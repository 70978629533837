<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

defineLayout({
  label: 'AgentCashPlus Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { setupHeader } = usePlayaLayout()

const pageStore = usePageStore()
const mbrData = useCookie('mbrData')

if (!isVoixTemplatizer)
  setupHeader()

const loggedOutMenu = ref({})

const mainMenu = ref({})
const iframeUrl = ref('')
const loading = ref(true)


onMounted(() => {
  if (mbrData.value) {
    $voixFetch(`/api/sites/9/menus/Main%20Menu`).then((result) => {
      mainMenu.value = result.data
    })

    iframeUrl.value = `https://mbr.agentcashplus.com/v2/?token=${mbrData.value.Token}&lang=${pageStore.currentPage.language_code}`
  } else {
    $voixFetch(`/api/sites/9/menus/Top%20Logged%20Out%20Menu`).then((result) => {
      loggedOutMenu.value = result.data
    })
  }

  const publicPaths = ['/', '/contact', 'fact-sheets', 'faq']
  if (!mbrData.value && !publicPaths.includes(pageStore.currentPage.path))
    location.href = '/'

  if (mbrData.value && pageStore.currentPage.path === '/')
    location.href = '/home'

    loading.value = false
})

provide('accentColor', { value: '#1d3091' })
provide('mbrData', mbrData.value)
</script>

<template>
  <ClientOnly>
    <div
      v-if="!loading"
      class="text-grey-darker bg-abs-white sanctuary-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />
      </div>

      <!-- Logged In -->
      <AgentCashPlusNavigation v-if="mbrData" :menu="mainMenu" class="font-sans">
        <div class="bg-white">
          <slot />

          <iframe
            v-if="pageStore.currentPage.path === '/my-booking-rewards'"
            allowfullscreen
            frameborder="0"
            scrolling="yes"
            seamless="seamless"
            class="w-full"
            :src="iframeUrl"
            style="width:100%;padding:0px;margin:0px;height:1700px;"
          />
          <AgentCashPlusFooter />
        </div>
      </AgentCashPlusNavigation>

      <div v-else class="relative">
        <div class="flex flex-col md:flex-row justify-between md:justify-end px-2 py-4 bg-[#01102d] text-white">
          <div class="relative md:absolute inset-0 pointer-events-none">
            <LogosAgentCashPlus :long="true" class="w-full h-10 text-white " />
          </div>
          <ul v-cloak class="flex flex-col md:flex-row items-center md:justify-end md:divide-x-2 divide-white/75 text-xs">
            <!-- TODO: Need to get the logged out menu. In D2 it was  `menuItem in currentPage?.site?.data?.loggedOutMenu.menu_items` -->
            <li v-for="menuItem in loggedOutMenu?.menu_items" :key="menuItem.id" class="px-4">
              <a :href="menuItem.permalink" :target="menuItem.target">{{ menuItem.name }}</a>
            </li>
          </ul>
        </div>
        <slot />
      </div>
    </div>
  </ClientOnly>
</template>

<style scoped>
  @import '~/assets/css/playaresorts.scss';
</style>
